<style scoped>
  .right-btns > * + * {
    margin-left: 10px;
  }
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col>
          <Row type="flex" :wrap="false" :gutter="10" align="middle">
            <template v-if="isPage">
              <i-col>仓库: </i-col>
              <i-col>
                <Select @on-change="onStorehouseIdChange" :value="currentStorehouseId" placeholder="请选择仓库\车间">
                  <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
                </Select>
              </i-col>
            </template>
            <i-col>
              <i-input placeholder="请输入搜索内容" search @on-blur="onSearch()" @on-search="onSearch()" @on-clear="onSearch()" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="goodsCode">货物编码</Option>
                </Select>
              </i-input>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <div class="right-btns">
            <template v-if="isPage">
              <Button v-if="$authFunsProxyNoRoute['scm.purchase.inbound-get']" :to="{
                name: 'scm.purchase.inbound',
                query: { back: true, storehouseId: currentStorehouseId }
              }">采购入库</Button>
              <Button v-if="$authFunsProxyNoRoute['scm.sale.outbound-get']" :to="{
                name: 'scm.sale.outbound',
                query: { back: true, storehouseId: currentStorehouseId }
              }">销售出库</Button>
              <Button v-if="$authFunsProxyNoRoute['scm.saleafter.outbound-get']" :to="{
                name: 'scm.saleafter.outbound',
                query: { back: true, storehouseId: currentStorehouseId }
              }">售后出库</Button>
              <Button v-if="$authFunsProxyNoRoute['scm.handle.exec-get']" :to="{
                name: 'scm.handle.exec',
                query: { back: true, storehouseId: currentStorehouseId }
              }">货物处置</Button>
              <Button v-if="$authFunsProxyNoRoute['scm.stock.outorder-get']" :to="{
                name: 'scm.stock.outorder',
                query: { back: true, storehouseId: currentStorehouseId }
              }">出库单出库</Button>
              <Button v-if="$authFunsProxyNoRoute['scm.stock.inorder-get']" :to="{
                name: 'scm.stock.inorder',
                query: { back: true, storehouseId: currentStorehouseId }
              }">入库单入库</Button>
            </template>
            <Button @click="refresh" :loading="loading.load" icon="md-refresh">刷新</Button>
            <Button @click="setStockItem(data, 'in')" icon="md-add" v-if="$authFunsProxy.in">入库</Button>
            <Button @click="showDetail" icon="md-list">出入库明细</Button>
          </div>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:goodsId="{ row }">
          <Tag class="noborder" :color="row.goodsUseTypeData.color">{{ row.goodsName }} - {{ row.goodsGgxh }}</Tag>
          <!-- <Tag class="noborder" :color="row.goodsUseTypeData.color">{{ row.goodsName }} - {{ row.goodsGgxh }} ({{ row.goodsUseTypeData.label }})</Tag> -->
        </template>
        <template v-slot:storehouseId="{ row }">
          {{ row.storehouseName }} / {{ row.storehouseUnitName }}
        </template>
        <template v-slot:volume="{ row }">
          {{ row.volume }}{{ row.goodsUnit || '' }}
        </template>
        <template v-slot:action="{ row }">
          <Button style="margin-right: 10px;" @click="setStockItem(row, 'in')" v-if="$authFunsProxy.in" size="small" type="text">入库</Button>
          <Button @click="setStockItem(row, 'out')" v-if="$authFunsProxy.out" size="small" type="text">出库</Button>
        </template>
      </Table>
    </template>
    <template v-slot:footer>
      <Drawer placement="right" v-if="$authFunsProxy.in || $authFunsProxy.out" :title="stockItem.type === 'in' ? '入库' : '出库'" :mask-closable="false" closable transfer draggable :width="30" v-model="status.form">
        <DrawerAction :loading="loading.submit" @confirm="submit" @cancel="status.form = false">
          <StockForm
            v-if="status.form"
            :type="stockItem.type"
            :data="stockItem.data"
            ref="form"
          />
        </DrawerAction>
      </Drawer>
      <Drawer placement="right" title="出入库明细" :mask-closable="false" closable transfer draggable :width="80" v-model="status.detail">
        <StockDetail style="height: 100%;margin: 0;padding: 0;" v-if="status.detail" :data="data" />
      </Drawer>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import DrawerAction from '../../components/drawer/action.vue'
import request from '../../api/index'
import StockForm from '../../components/stock/form/stock.vue'
import StockDetail from './detail.vue'

export default {
  components: { AppPage, StockForm, DrawerAction, StockDetail },
  props: {
    data: { type: Object, default: null },
    isPage: { type: Boolean, default: true }
  },
  data () {
    return {
      routerName: this.$route.name,
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      query: {
        key: 'goodsCode',
        value: null
      },
      loading: {
        load: false,
        submit: false,
        del: false
      },
      dataList: [],
      status: {
        form: false,
        detail: false
      },
      stockItem: {
        type: 'in',
        data: null
      }
    }
  },
  computed: {
    currentStorehouseId () {
      const storehouseId = this.$route.query.storehouseId
      return storehouseId ? Number(storehouseId) : null
    },
    defaultStorehouseId () {
      return this.isPage && this.scmStoreHouseList.length ? this.scmStoreHouseList[0].id : null
    },
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    },
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    columns () {
      return [
        // { title: '编码', key: 'code' },
        { title: '仓库', key: 'storehouseId', slot: 'storehouseId' },
        { title: '货物', key: 'goodsId', slot: 'goodsId' },
        { title: '货物编码', key: 'goodsCode'},
        { title: '数量', key: 'volume', slot: 'volume' },
        { title: '操作', key: 'action', slot: 'action', width: 180 },
      ]
    }
  },
  watch: {
    currentStorehouseId: {
      handler () {
        this.refresh()
      }
    }
  },
  methods: {
    onStorehouseIdChange (id) {
      this.$router.replace({
        name: this.routerName,
        query: {
          storehouseId: id
        }
      })
    },
    showDetail () {
      this.status.detail = true
    },
    async submit () {
      try {
        const data = await this.$refs.form.getFormData()
        if (!data) {
          return this.$Message.warning('请完整填写表单')
        }
        this.loading.submit = true
        await request.post('/goods_stock/' + (this.stockItem.type === 'in' ? 'direct_in' : 'direct_out'), data)
        this.$Message.success('操作成功')
        this.status.form = false
        this.loadData()
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.submit = false
    },
    setStockItem (item, type) {
      this.stockItem.type = type
      this.stockItem.data = item ? {
        goodsId: item.goodsId,
        storehouseId: item.storehouseId || this.query.storehouseId,
        storehouseUnitId: item.storehouseUnitId,
        id: item.id
      } : {
        goodsId: null,
        storehouseId: this.query.storehouseId || null,
        storehouseUnitId: null,
        id: null
      }
      this.status.form = true
    },
    onSearch (params) {
      if (params) {
        Object.assign(this.query, params)
      }
      this.pager.page = 1
      this.loadData()
    },
    refresh () {
      this.pager.page = 1
      this.query.key = 'goodsCode'
      this.query.value = null
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      if (!this.$authFunsProxy.get) {
        return
      }
      const params = {}

      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }

      if (this.data) {
        params.goodsId = this.data.goodsId
      }

      if (this.currentStorehouseId) {
        params.storehouseId = this.currentStorehouseId
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsUseTypeData = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    async onRowsChange (rows) {
      await this.$store.dispatch('loadStoreHouse')
      this.pager.page = 1
      this.pager.limit = rows
      if (!this.currentStorehouseId && this.isPage) {
        this.onStorehouseIdChange(this.defaultStorehouseId)
      } else {
        this.loadData()
      }
    }
  }
}
</script>
