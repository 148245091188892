<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :key="type" :rules="rules">
    <FormItem label="动作" prop="action">
      <Select v-model="formData.action" placeholder="请选择动作">
        <Option v-for="item in directActions" :key="item.key" :value="item.key">{{ item.label }}</Option>
      </Select>
    </FormItem>
    <FormItem label="物品" prop="goodsId" v-if="type === 'in'">
      <GoodsChoose v-model="formData.goodsId" />
    </FormItem>
    <!-- <FormItem label="生产批次" prop="produceBatch" v-if="type === 'in'">
      <Input v-model="formData.produceBatch" placeholder="请输入生产批次" />
    </FormItem>
    <FormItem label="生产日期" prop="produceTime" v-if="type === 'in'">
      <DatePicker :value="formData.produceTime" @on-change="date = formData.produceTime" type="datetime" placeholder="请选择生产日期"></DatePicker>
    </FormItem>
    <FormItem label="过期时间" prop="exceedTime" v-if="type === 'in'">
      <DatePicker :value="formData.exceedTime" @on-change="date = formData.exceedTime" type="datetime" placeholder="请选择过期时间"></DatePicker>
    </FormItem> -->
    <FormItem label="数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
    <FormItem v-if="type === 'in'" label="仓库\车间" prop="storehouseId">
      <Select v-model="formData.storehouseId" placeholder="请选择仓库\车间">
        <Option v-for="item in scmStoreHouseList" :key="item.id" :value="item.id">{{ item.name }}</Option>
      </Select>
    </FormItem>
    <FormItem v-if="formData.storehouseId && type === 'in'" label="仓库单元" prop="storehouseUnitId">
      <UnitChoose :storehouseId="formData.storehouseId" v-model="formData.storehouseUnitId" />
    </FormItem>
    <FormItem label="备注" prop="remark">
      <Input type="textarea" v-model="formData.remark" placeholder="请输入备注" />
    </FormItem>
  </Form>
</template>

<script>
import GoodsChoose from '../../goods/base/choose.vue'
import UnitChoose from '../../stock/choose/unit.vue'

export function getDefault (type) {
  return type === 'in' ? {
    goodsId: null,
    // produceBatch: null,
    // produceTime: null,
    // exceedTime: null,
    volume: null,
    storehouseId: null,
    storehouseUnitId: null,
    action: null,
    remark: null
  } : {
    volume: null,
    goodsStockId: null,
    action: null,
    remark: null
  }
}

export default {
  components: { GoodsChoose, UnitChoose },
  props: {
    type: { type: String, default: 'in' },
    data: { type: Object, default: null }
  },
  data () {
    const formData = getDefault(this.type)
    if (this.data && this.type === 'in') {
      formData.goodsId = this.data.goodsId
      formData.storehouseId = this.data.storehouseId
      formData.storehouseUnitId = this.data.storehouseUnitId
    }
    return {
      formData,
      loading: {
        search: false
      },
      directActions: [{key: 'check', label: '盘点'}, {key: 'produce', label: '生产'}]
    }
  },
  computed: {
    rules () {
      return this.type === 'in' ? {
        goodsId: [
          { required: true, message: '请选择货物' }
        ],
        volume: [
          { required: true, message: '请输入数量' }
        ],
        storehouseId: [
          { required: true, message: '请选择仓库单元' }
        ],
        storehouseUnitId: [
          { required: true, message: '请选择仓库单元' }
        ]
      } : {
        volume: [
          { required: true, message: '请输入数量' }
        ]
      }
    },
    scmStoreHouseList () {
      return this.$store.getters.scmStoreHouseList
    }
  },
  watch: {
    type () {
      this.formData = getDefault(this.type)
    },
    data: {
      deep: true,
      handler () {
        if (this.type === 'in' && this.data) {
          this.formData.goodsId = this.data.goodsId
          this.formData.storehouseId = this.data.storehouseId
          this.formData.storehouseUnitId = this.data.storehouseUnitId
        }
      }
    }
  },
  methods: {
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const formData = JSON.parse(JSON.stringify(this.formData))
            if (this.type === 'out' && this.data) {
              formData.goodsStockId = this.data.id
            }
            resolve(formData)
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadStoreHouse')
  }
}
</script>
