<style scoped>
</style>

<template>
  <AppPage @on-rows-change="onRowsChange" :page="pager.page" :limit="pager.limit" :total="pager.total" @on-page-change="onPageChange">
    <template v-slot:header>
      <Row type="flex" justify="space-between" align="middle" style="margin-bottom: 10px;">
        <i-col></i-col>
        <i-col>
          <Button @click="refresh" icon="md-refresh">刷新</Button>
        </i-col>
      </Row>
    </template>
    <template v-slot="{ contentHeight }">
      <Table :height="contentHeight" :data="dataList" :columns="columns" stripe :loading="loading.load">
        <template v-slot:goodsId="{ row }">
          <Tooltip :content="row.goodsCode">
            <Tag class="noborder" :color="row.goodsUseTypeData.color">{{ row.goodsName }} ({{ row.goodsUseTypeData.label }})</Tag>
          </Tooltip>
        </template>
        <template v-slot:storehouseId="{ row }">
          {{ row.storehouseName }} / {{ row.storehouseUnitName }}
        </template>
        <template v-slot:realVolume="{ row }">
          {{ row.realVolume }}{{ row.goodsUnit || '' }}
        </template>
        <template v-slot:type="{ row }">
          <Tag v-if="row.type === 1" color="geekblue" class="noborder">入库</Tag>
          <Tag v-else-if="row.type === 2" color="purple" class="noborder">出库</Tag>
        </template>
        <template v-slot:status="{ row }">
          <Tag v-if="row.status === 0" color="green" class="noborder">计划</Tag>
          <Tag v-else-if="row.status === 1" color="blue" class="noborder">进行中</Tag>
          <Tag v-else-if="row.status === 2" color="geekblue" class="noborder">完成</Tag>
          <Tag v-else color="red" class="noborder">撤销</Tag>
        </template>
        <template v-slot:action="{ row }">
          {{ row.actionData.label }}
        </template>
      </Table>
    </template>
  </AppPage>
</template>

<script>
import AppPage from '../../components/page/base.vue'
import request from '../../api/index'

export default {
  components: { AppPage },
  props: {
    data: { type: Object, default: null }
  },
  data () {
    return {
      pager: {
        page: 1,
        limit: 10,
        total: 0
      },
      loading: {
        load: false,
      },
      dataList: [],
    }
  },
  computed: {
    scmGoodsUseTypeList () {
      return this.$store.getters.scmGoodsUseTypeList
    },
    scmStockDetailActionList () {
      return this.$store.getters.scmStockDetailActionList
    },
    columns () {
      return [
        // { title: '编码', key: 'code', fixed: 'left', ellipsis: true, tooltip: true, width: 130 },
        { title: '货物', key: 'goodsId', ellipsis: true, tooltip: true, width: 130, slot: 'goodsId', fixed: 'left' },
        { title: '货物编码', key: 'goodsCode', ellipsis: true, tooltip: true, minWidth: 120 },
        { title: '规格型号', key: 'goodsGgxh', ellipsis: true, tooltip: true, minWidth: 120 },
        { title: '数量', key: 'realVolume', slot: 'realVolume', ellipsis: true, tooltip: true, width: 120 },
        { title: '仓库', key: 'storehouseId', slot: 'storehouseId', ellipsis: true, tooltip: true, minWidth: 140 },
        { title: '完成时间', key: 'endTime', tooltip: true, minWidth: 180 },
        { title: '处理人', key: 'userName', tooltip: true, minWidth: 100 },
        { title: '备注', key: 'remark', ellipsis: true, tooltip: true, minWidth: 160 },
        { title: '类型', key: 'type', slot: 'type', width: 80, fixed: 'right' },
        // { title: '状态', key: 'status', slot: 'status', width: 80, fixed: 'right' },
        { title: '动作', key: 'action', slot: 'action', width: 80, fixed: 'right' },
      ]
    }
  },
  methods: {
    refresh () {
      this.pager.page = 1
      this.loadData()
    },
    async onPageChange (page) {
      this.pager.page = page
      this.loadData()
    },
    async loadData () {
      const params = {}

      if (this.data) {
        if (this.data.goodsId) {
          params.goodsId = this.data.goodsId
        }
        if (this.data.goodsStockId) {
          params.goodsStockId = this.data.goodsStockId
        }
      }

      this.loading.load = true
      try {
        const res = await request.get('/goods_stock/detail', Object.assign(params, {
          pageNum: this.pager.page,
          pageSize: this.pager.limit
        }))

        res.data.forEach(item => {
          item.goodsUseTypeData = this.scmGoodsUseTypeList.find(v => v.value === item.goodsUseType)
          item.actionData = this.scmStockDetailActionList.find(v => v.value === item.action)
        })

        this.pager.total = res.total
        this.dataList = res.data
      } catch (e) {
        this.$Modal.error({ title: '系统提示', content: e.message })
      }
      this.loading.load = false
    },
    onRowsChange (rows) {
      this.pager.page = 1
      this.pager.limit = rows
      this.loadData()
    }
  }
}
</script>
